<template>
    <div class="screensaver">
        <div class="page-top">
            <div class="logo" @click="goto('/index/')">
                <div class="picbox">
                    <div class="pic" style="background-image: url(/img/logo.png)"></div>
                </div>
            </div>
        </div>
        <div class="mainbox">
            <div class="titlebox">
                <div class="atitle">“我嘉·心晴加油站”</div>
                <div class="btitle">心理健康智慧终端</div>
            </div>
            <div class="conbox">
                “我嘉·心晴加油站”是在嘉定区公立医院改革与高质量发展项目的引领下，由嘉定区卫生健康委员会指导，区精神卫生中心全力打造的集健康科普、心理测量、咨询服务等一体化服务为核心的心理健康智能平台，旨在为公众提供专业、便捷的心理健康服务。
            </div>
            <div class="btns">
                <div class="btn" @click="goto('/about')">关于心晴加油站</div>
                <div class="btn" @click="goto('/index')">进入主页</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Screensaver',
    components: {

    },
    data() {
        return {
            
        }
    },
    methods: {
        goto(adress, cate) {
            this.$router.push({path: adress, query: cate});
        }
    },
    mounted() {
        console.log("window.location.href=", window.location.href);
        console.log("webrtcId0", this.$route.query.webrtcId);
        console.log("webrtcIndex0", this.$route.query.index);
        if(this.$route.query.webrtcId != null && this.$route.query.webrtcId != undefined){
            window.localStorage.setItem('webrtcId', this.$route.query.webrtcId); 
        }
        if(this.$route.query.index != null && this.$route.query.index !=undefined){
            window.localStorage.setItem('webrtcIndex', this.$route.query.index); 
        }
        const webrtcId = window.localStorage.getItem('webrtcId');
        let webrtcIndex = window.localStorage.getItem('webrtcIndex');
        if(webrtcId != null && webrtcId != undefined && webrtcId != "" &&
            webrtcIndex != null && webrtcIndex != undefined && webrtcIndex != ""){
            console.log("webrtcIndex2", webrtcIndex);
            webrtcIndex = webrtcIndex.split("?")[0];
            console.log("webrtcIndex3", webrtcIndex);
            
            window.localStorage.setItem('currentWebrtcId', webrtcId);

        }
        this.dialogVisible = false;
        this.$root.hideRight();
    }
}
</script>

<style lang="scss">
.screensaver {
    width: 1400px;
    margin: 0 auto;
    padding: 60px 0;
    .page-top {
        @include sys-flex(space-between, row);
        .logo {            
            @include sys-picbox(438px, 80px);
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }
    .mainbox {
        width: 1000px;
        height: 100%;
        max-height: calc(100% - 260px);
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include sys-flex();
        .titlebox {
            text-align: center;
            .atitle {
                @include sys-font(68px, #000, bold);
                line-height: 80px;
            }
            .btitle {
                @include sys-font(48px, #000);
                line-height: 60px;
            }
        }
        .conbox {
            @include sys-font(24px, #000);
            line-height: 40px;
            padding: 45px 0 110px;
        }
        .btns {
            @include sys-flex(center, row);
            .btn {
                @include sys-flex();
                width: 220px;
                height: 60px;
                border-radius: 40px;
                margin: 0 10px;
                font-size: 20px;
                &:first-child {
                    border: 2px solid #3FB082;
                    color: #3FB082;
                }
                &:last-child {
                    background: #3FB082;
                    color: #fff;
                }
            }
        }
    }
}
</style>